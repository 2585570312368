<mat-chip-set
  aria-label="Folder Location"
  class="breadcrumb-icon"
  [ngClass]="{ 'grayed-out': folderLevel === 0 }"
  (click)="folderLevel === 0 ? $event.stopPropagation() : toggleExpansion($event)">
  <mat-chip [class.expanded]="isExpanded">
    <div class="breadcrumb-content">
      <mat-icon>location_on</mat-icon>
      <span *ngIf="isExpanded" class="breadcrumb-text">{{ breadcrumbPath }}</span>
    </div>
  </mat-chip>
</mat-chip-set>
