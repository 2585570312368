import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { isErrorResponse } from '../../error_service/error_response';
import { Resource, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { SnackBarService } from '../../services/snackbar_service';

/**
 * Cloud ingest creation dialog
 */
@Component({
  selector: 'mam-clipbin-creation-folder-dialog',
  templateUrl: './clipbin_folder_creation_dialog.ng.html',
  styleUrls: ['./clipbin_folder_creation_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipbinFolderCreationDialog implements OnInit {
  readonly form = new UntypedFormGroup({
    folderName: new UntypedFormControl('', Validators.required),
  });

  contextId = window.location.pathname.split('/').pop() || '';
  currentContext!: Resource;
  keyEnabled = true;
  isAnotherOwnedFolder: boolean = false;

  get maxLevelRestriction() {
    return this.currentContext ? (this.currentContext.level >= this.resourceService.MAX_FOLDER_DEPTH) : false;
  }

  constructor(
    private readonly snackBar: SnackBarService,
    readonly dialogRef: MatDialogRef<ClipbinFolderCreationDialog>,
    readonly resourceService: ResourceService,
    readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    if (this.contextId) {
      this.form.controls['folderName'].disable();
      this.resourceService.getResourceById('folder', this.contextId)
        .pipe(take(1))
        .subscribe(folder => {
          this.currentContext = folder;
          this.isAnotherOwnedFolder = this.currentContext.owner !== this.authService.getUserEmail();

          if (!this.maxLevelRestriction && !this.isAnotherOwnedFolder) {
            this.form.controls['folderName'].enable();
          }
          this.cdr.detectChanges();
        });
    }
  }

  submit() {
    if (!this.form.valid) return;
    this.keyEnabled = false;

    const formControls = this.form.controls;
    const folderName = formControls['folderName'].value;
    const parentId = window.location.pathname.split('/').pop() || '';
    this.resourceService.createResource(`folder`, {
      name: folderName,
      owner: this.authService.getUserEmail(),
      ownerName: this.authService.getUserName(),
      parentId,
    }, parentId)
      .pipe(take(1))
      .subscribe(result => {
        if (isErrorResponse(result)) {
          this.snackBar.error({
            message: 'Create folder failed.',
            details: result.message,
            doNotLog: true,
          });
          this.keyEnabled = true;
        } else {
          this.snackBar.message('Folder has been created');
          this.dialogRef.close();
        }
      });
  }



}
