import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, takeUntil } from 'rxjs';

import { assertTruthy } from 'asserts/asserts';
import { ErrorResponse } from 'error_service/error_response';
import { Resource } from 'landing/clip-bin-section/service/resource.service';
import { ContentEvent } from 'landing/folder-content/folder-content.component';
import { DisplayMode } from 'services/vod_search_service';
import { GetLinkForBinDialog } from 'shared/get_link_for_bin_dialog';
import { MoveBinDialog } from 'shared/move_bin_dialog/move_bin_dialog';

import { AssetService, Clip, ListResponse } from '../../services/asset_service';
import { Bin, BinWithClips } from '../../services/bin.service';
import { DeleteBinDialog } from '../../shared/delete_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';

/**
 * Clickable clip bin card with up to 3 clips previews.
 */
@Component({
  selector: 'mam-clip-bin-preview',
  templateUrl: './clip-bin-preview.ng.html',
  styleUrls: ['./clip-bin-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipBinPreview implements OnInit, OnDestroy {
  @Input() bin!: BinWithClips | Bin | Resource;

  // toggles between grid and list view
  @Input() binsViewMode: DisplayMode = DisplayMode.GRID;

  //toggles between active and inactive ina selection
  @Input() active: boolean = false;

  @Input() isAdmin: boolean = false;
  @Input() showAllBins: boolean | undefined = undefined;
  @Input() isMyFolder: boolean | undefined = undefined;

  @Output() contentEvent = new EventEmitter<ContentEvent>();

  @Input() sharingAdditionalProperties?: Record<string, string>;

  @Input() checkboxPresent: boolean = false;

  get binValue(): BinWithClips | Bin  {
    return this.bin as BinWithClips | Bin;
  }

  clips: Clip[] = [];

  /** True when layout switches to SMALL. */
  isSmallScreen = false;

  /** True when layout switches to MID. */
  isMidScreen = false;

  /** True when layout switches to Large. */
  isLargeScreen = false;

  /** True when layout switches to SMALL Landscape mode. */
  isSmallScreenLandScape = false;

  get binWithClips(): BinWithClips {
    const bin = this.bin as BinWithClips;

    if (this.clips.length > 0) {
      bin.clips = this.clips;
    }

    return bin;
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly assetService: AssetService,
  ) { }

  ngOnInit() {
    this.getClips();
    assertTruthy(this.bin, '<mam-clip-bin-preview> requires property "bin"');
  }

  getClips() {
    this.assetService.searchClips(this.bin.name, undefined, undefined, 5).subscribe((clipResult) => {
      if (clipResult instanceof ErrorResponse) {
        return;
      }

      this.clips = (clipResult as ListResponse<Clip>).assets;
      this.cdr.markForCheck();
    });
  }

  trackClip(index: number, clip: Clip) {
    return clip.name;
  }

  openRenameBin(title: string, name: string) {
    this.dialog.open(RenameBinDialog, {
      ...RenameBinDialog.dialogOptions,
      data: { title, name },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe((newTitle) => {
      if (!newTitle) { return; }
      if ('title' in this.bin) {
        this.bin.title = newTitle;
      }
      const binEventResult = { ...this.bin, retiitled: newTitle };
      this.contentEvent.emit(binEventResult as unknown as ContentEvent);
      this.cdr.markForCheck();
    });
  }

  openDeleteBin(name: string) {
    this.dialog.open(DeleteBinDialog, {
      ...DeleteBinDialog.dialogOptions,
      data: { name },
    })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((deletionConfirmed) => {
        const binEventResult = { ...this.bin, deleted: deletionConfirmed };
        this.contentEvent.emit(binEventResult as unknown as ContentEvent);
      });
  }

  moveToFolder() {
    this.dialog.open(MoveBinDialog, {
      ...MoveBinDialog.dialogOptions,
      data: this.bin,
    })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((moveConfirmed) => {
        const binEventResult = { ...this.bin, moved: moveConfirmed };
        this.contentEvent.emit(binEventResult as unknown as ContentEvent);
      });
  }

  openShareBin() {
    this.dialog.open(
      GetLinkForBinDialog, GetLinkForBinDialog.getDialogOptions({
        bin: this.bin as BinWithClips,
        additionalProperties: this.sharingAdditionalProperties,
      }));
  }

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
