import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../../auth/auth_service';
import { environment } from '../../../environments/environment';

export type ResourceType = 'clip' | 'clip-bin' | 'folder';

export interface PaginationInfo {
  limit: number;
  offset: number;
  nextToken?: string;
  startAfter?: string;
  paginationResult?: PaginationResult;
}

export interface PaginationResult {
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface Resource {
  id?: string;
  name: string;
  displayName: string;
  type?: ResourceType;
  url?: string;
  createdAt: string;
  updatedAt?: string;
  parentId?: string;
  createdBy?: string;
  level: number;
  items?: number;
  breadcrumbPath?: string;
  breadcrumb?: Resource[];
  directChildrenCount?: number;
  owner?: string;
  ownerName?: string;
  parent?: Resource;
  subTreeDepth: number;
}

export interface ResourceResult {
  folders: Resource[],
  paginationData: PaginationResult
}

export interface ResourceCreationData {
  name: string;
  parentId?: string;
  ownerName?: string;
  owner?: string;
}

export interface SearchOptions {
  searchTerm: string;
  owner?: string;
  parent?: string;
  type?: ResourceType;
}

export interface LastResourcePerPage {
  pageIndex: number;
  resourceId: string;
}

export interface ResourceContent {
  parent: {
    id: string;
    name: string;
    type: ResourceType;
    breadcrumb: Resource[];
    directChildrenCount: number;
    level?: number;
    children: Resource[];
  }
  paginationData?: PaginationInfo;
}

export interface MoveFolderDestination {
  newParentId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private readonly BASE_URL = environment.resourcesApi;

  readonly MAX_FOLDER_DEPTH = 2;

  // BehaviorSubjects to persist the current resource page and pagination info
  currentResources$ = new BehaviorSubject<Resource[]>([]);
  currentContext$ = new BehaviorSubject<ResourceContent>({} as ResourceContent);
  paginationInfo$ = new BehaviorSubject<PaginationInfo>({ limit: 12, offset: 0 });
  pageChanged$ = new Subject();

  searchOptions$ = new BehaviorSubject<SearchOptions>({ searchTerm: '', owner: '' });
  lastResourcePerPageArray: LastResourcePerPage[] = [];
  lastMoveResourcePerPageArray: LastResourcePerPage[] = [];

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) { }

  private getAuthHeaders(): HttpHeaders {
    const accessToken = this.authService.getAccessToken();
    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
  }

  /** Health Check */
  healthCheckResourcesAPI() {
    return this.httpClient.get(`${this.BASE_URL}/health`, { headers: this.getAuthHeaders() });
  }

  getResourceById(type: ResourceType, resourceId: string) {
    return this.httpClient.get<Resource>(`${this.BASE_URL}/${type}s/${resourceId}`, { headers: this.getAuthHeaders() });
  }

  /** Generic method to fetch resources of any type */
  getResource(type: ResourceType, pagination?: PaginationInfo, searchOptions?: SearchOptions, updateResource: boolean = true, isMoveDialog: boolean = false) {
    let params = new HttpParams();

    if (searchOptions) {
      params = new HttpParams();
      if (searchOptions.searchTerm) params = params.set('searchTerm', searchOptions.searchTerm);
      if (searchOptions.owner && (searchOptions.owner !== 'all_users' && searchOptions.owner !== '')) params = params.set('owner', searchOptions.owner);
      if (searchOptions.parent) params = params.set('parent', searchOptions.parent);
      if (searchOptions.owner) params = params.set('type', searchOptions.type || 'folder');
    }

    if (pagination) {
      params = this.buildPaginationParams(pagination, params);
    }

    return this.httpClient
      .get<unknown>(`${this.BASE_URL}/${type}s`, { headers: this.getAuthHeaders(), params })
      .pipe(
        tap((resources) => {
          const currentResources = (resources as { [key: string]: Resource[] })[type.toString() + 's'];
          if (pagination && currentResources && currentResources.length && currentResources[currentResources.length - 1]?.id) {
            const paginationResult = (resources as { [key: string]: PaginationResult })['paginationData'];
            pagination.paginationResult = paginationResult;
            if (updateResource) this.paginationInfo$.next(pagination);
            this.addOrUpdateLastResourcePerPage(pagination.offset, currentResources[currentResources.length - 1].id as string, isMoveDialog);
          }
          if (!currentResources.length) {
            pagination = { paginationResult: { pageSize: 0, totalItems: 0, totalPages: 0 } } as PaginationInfo;
            if (updateResource) this.paginationInfo$.next(pagination);
          }
          if (updateResource) this.currentResources$.next(currentResources);
          if (searchOptions && updateResource) this.searchOptions$.next(searchOptions);
        })
      );
  }

  addOrUpdateLastResourcePerPage(pageIndex: number, resourceId: string, isMoveDialog: boolean) {
    const getResourceOnPageIndex = !isMoveDialog
      ? this.lastResourcePerPageArray.findIndex(item => item.pageIndex === pageIndex)
      : this.lastMoveResourcePerPageArray.findIndex(item => item.pageIndex === pageIndex);

    if (getResourceOnPageIndex === -1) {
      if(!isMoveDialog){
        this.lastResourcePerPageArray.push({ pageIndex: pageIndex, resourceId: resourceId });
      } else {
        this.lastMoveResourcePerPageArray.push({ pageIndex: pageIndex, resourceId: resourceId });
      }
    } else {
      if(!isMoveDialog){
        this.lastResourcePerPageArray[getResourceOnPageIndex] = { pageIndex: pageIndex, resourceId: resourceId };
      } else {
        this.lastMoveResourcePerPageArray[getResourceOnPageIndex] = { pageIndex: pageIndex, resourceId: resourceId };
      }
    }
  }

  getLastResourceByPage(pageIndex: number) {
    const getResourceOnPageIndex = this.lastResourcePerPageArray.findIndex(item => item.pageIndex === pageIndex);
    return getResourceOnPageIndex > -1 ? this.lastResourcePerPageArray[getResourceOnPageIndex] : null;
  }

  getResourceChildren(type: ResourceType, parentId: string, pagination?: PaginationInfo, updateResource: boolean = true, isMoveDialog: boolean = false) {
    let params = new HttpParams();
    if (pagination) {
      params = this.buildPaginationParams(pagination);
    }
    return this.httpClient
      .get<ResourceContent>(`${this.BASE_URL}/${type}s/${parentId}/children`, { headers: this.getAuthHeaders(), params })
      .pipe(
        tap((resourceContent) => {
          if (updateResource) this.currentContext$.next(resourceContent);
          if (pagination && resourceContent.paginationData) {
            const paginationResult = resourceContent.paginationData as unknown as PaginationResult;
            pagination.paginationResult = paginationResult;
            if (updateResource) this.paginationInfo$.next(pagination);
            const children = this.currentContext$.value.parent?.children;
            if(children && children.length > 0) this.addOrUpdateLastResourcePerPage(resourceContent.paginationData.offset, children[children.length - 1]?.id as string, isMoveDialog);
          }
        })
      );
  }

  createResource(type: ResourceType, creationData: ResourceCreationData, context?: string) {
    return this.httpClient
      .post<Resource>(`${this.BASE_URL}/${type}s`, creationData, { headers: this.getAuthHeaders() })
      .pipe(
        switchMap(() =>
          context ? this.getResourceChildren(type, context, this.paginationInfo$.value).toPromise() as Promise<ResourceContent> :
            this.getResource(type, {limit: 12, offset: 0}, this.searchOptions$.value).toPromise() as Promise<Resource[]>)
      );
  }

  updateResource(type: ResourceType, resourceId: string, updateData: Partial<Resource>, context?: string) {
    return this.httpClient
      .put<Resource>(`${this.BASE_URL}/${type}s/${resourceId}`, updateData, { headers: this.getAuthHeaders() })
      .pipe(
        switchMap(() => {
          const pagination = this.paginationInfo$.value;
          const searchOptions = this.searchOptions$.value;
          return context ? this.getResourceChildren(type, context, pagination).toPromise() as Promise<ResourceContent> :
            this.getResource(type, pagination, searchOptions).toPromise() as Promise<Resource[]>;

        })
      );
  }

  moveResource(type: ResourceType, resourceId: string, newParentId: string) {
    return this.httpClient
      .put<Resource>(`${this.BASE_URL}/${type}s/${resourceId}/move`, { "newParentId": newParentId }, { headers: this.getAuthHeaders() })
      .pipe(
        switchMap(() => {
          return this.getResourceChildren(type, newParentId).toPromise() as Promise<ResourceContent>;
        })
      );
  }

  deleteResource(type: ResourceType, resourceId: string, context?: string) {
    return this.httpClient
      .delete(`${this.BASE_URL}/${type}s/${resourceId}`, { headers: this.getAuthHeaders() })
      .pipe(
        switchMap(() => {
          const pagination = this.paginationInfo$.value;
          const searchOptions = this.searchOptions$.value;

          if (pagination.paginationResult && pagination.offset > 0) {
            pagination.paginationResult.totalItems--;
            if (pagination.paginationResult.pageSize * pagination.offset >= pagination.paginationResult.totalItems) {
              pagination.offset--;
              if (pagination.offset > 0) {
                const startAfter = this.getLastResourceByPage(pagination.offset - 1);
                if (startAfter) pagination.startAfter = startAfter.resourceId;
              } else {
                pagination.startAfter = '';
              }
            }
          }

          return context ? this.getResourceChildren(type, context, pagination).toPromise() as Promise<ResourceContent> :
            this.getResource(type, pagination, searchOptions).toPromise() as Promise<Resource[]>;
        })
      );
  }

  /** Helper method to build pagination params */
  private buildPaginationParams(pagination: PaginationInfo, params: HttpParams = new HttpParams()): HttpParams {
    if (pagination.limit) params = params.set('pageSize', pagination.limit.toString());
    if (pagination.offset) params = params.set('offset', pagination.offset.toString());
    if (pagination.nextToken) params = params.set('nextToken', pagination.nextToken);
    if (pagination.startAfter) params = params.set('startAfter', pagination.startAfter);
    return params;
  }
}


