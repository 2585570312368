import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { EMPTY, switchMap, take } from 'rxjs';


import { ResourceService } from '../landing/clip-bin-section/service/resource.service';
import {SnackBarService} from '../services/snackbar_service';


/** Clipbin creation dialog */
@Component({
  selector: 'mam-create-bin-dialog',
  templateUrl: './create_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

      @include breakpoint-md-max {
        max-width: 95vw;
        width: 65vw;
        }

      @include breakpoint-sm-max {
        width: initial;
        max-width: initial;
      }
      }`
  ]
})
export class CreateBinDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };
  constructor(
      readonly snackBar: SnackBarService,
      readonly resourceService: ResourceService,
      readonly dialogRef: MatDialogRef<CreateBinDialog>,
  ) {}

  ngOnInit() {
    this.dialogRef.afterClosed().pipe(
      switchMap((name?: string) => {
        if (!name?.trim()) {
          return EMPTY;
        }
        return this.resourceService.createResource('clip-bin', {
          name: name,
        }).pipe(take(1));
      })
    ).subscribe({
      next: () => {
        this.snackBar.message('Clip bin has been created successfully');
      },
      error: (error: unknown) => {
        this.snackBar.error('Clip bin could not be created.', undefined, error);
      }
    });
  }
}

