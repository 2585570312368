<h1 mat-dialog-title>Create New Folder</h1>
<div mat-dialog-content
    (keyup.enter)="keyEnabled && submit()"
>
  <br>
  <p
    class="error"
    *ngIf="maxLevelRestriction"
    [hidden]="!maxLevelRestriction">You can't create folders more than 3 levels deep</p>
  <p
    class="error"
    *ngIf="isAnotherOwnedFolder">Unable to create folder: You can only create folders within your own folders.</p>
  <form [formGroup]="form">
    <mat-form-field class="name-field" appearance="outline"floatLabel="always">
      <input
          matInput
          required
          placeholder="Folder name"
          autocomplete="off"
          formControlName="folderName" />
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel cloud ingest creation"
      mat-button
      mat-dialog-close>
    Cancel
  </button>

  <button
      mat-button
      class="primary"
      mam-firebase-ga="Confirm cloud ingest creation"
      mat-dialog-close
      [disabled]="!form.valid"
      (click)="submit()">
    Create
  </button>
</div>
