import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { environment } from '../../environments/environment';
import { FolderItemRef } from '../../firebase/clip_bins_folders.service';
import { Resource } from '../clip-bin-section/service/resource.service';

export interface BreadcrumbItem {
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private readonly BASE_URL = environment.resourcesApi;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    getBreadcrumbs(id: string): Observable<BreadcrumbItem[]> {
        return this.httpClient.get<BreadcrumbItem[]>(`${this.BASE_URL}/folders/${id}/breadcrumb`, {
            headers: this.getAuthHeaders()
        });
    }

    private getAuthHeaders(): HttpHeaders {
        const accessToken = this.authService.getAccessToken();
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
        });
    }
}

@Component({
    selector: 'mam-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
    @Input() folder: Resource | FolderItemRef | undefined = undefined;
    @Input() breadcrumbPath: string | undefined = undefined;
    hasChildren: boolean = false;
    isExpanded: boolean = false;
    folderLevel: number = 0;

    constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.folder && this.folder.directChildrenCount) {
            this.hasChildren = this.folder?.directChildrenCount > 0 || false;
        }
        this.folderLevel = this.folder?.level || 0;
    }

    toggleExpansion(event: MouseEvent): void {
        event.stopPropagation();
        this.isExpanded = !this.isExpanded;
        if (this.folder && this.folder.id) {
            this.breadcrumbService.getBreadcrumbs(this.folder.id || '').subscribe((breadcrumbs) => {
                this.breadcrumbPath = breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
                this.cdr.markForCheck();
            });
        }
    }
}
