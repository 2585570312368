import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';

import {makeFakeOriginal} from './asset_api_fake_service';
import {Asset, Clip, ClipMarking} from './asset_service';
import {ClipbinsOwner} from './bin_api.service';
import {KeywordResult} from './search_service';

/** Persistent Tabs types  */
export type PersistentTab =
    'play-feed'|'insights'|'metadata'|'related-views'|'clipbins'|'staging';

/** View types for center-content area. */
export enum HomeView {
  ADMIN = 'admin',
  DETAILS = 'details',
  LANDING = 'landing',
  LIVE = 'live',
  JOBS = 'jobs',
  STAGING = 'staging',
  SEARCH_RESULTS = 'search_results',
}

export enum ShortcutEvents {
  CREATE_CLIP_SEGMENT = 'create_clip_segment',
  SHOW_SOURCE_ASSET = 'show_source_asset'
}

/** Emitted by a player when its time or paused state changes. */
export interface PlayerUpdate {
  time?: number;
  paused: boolean;
}

export interface AssetTrack {
    trackIndex: number | undefined;
    track: shaka.extern.Track | undefined;
}

export interface ShortcutEvent {
  key: string;
  intent: ShortcutEvents;
  targetTab?: PersistentTab;
  targetView?: HomeView
}

/**
 * Service responsible for keeping track of the state of the app. This service
 * should be shared across multiple components.
 */
@Injectable({ providedIn: 'root' })
export class StateService {
    /** Current view displayed. */
    readonly currentView$ = new BehaviorSubject<HomeView>(HomeView.LANDING);

    /**
     * Whether the current persistent panel tab is loading data. This will
     * display a progress bar on top of the panel.
     */
    readonly isPanelLoading$ = new BehaviorSubject(false);

    /**
     * Current asset displayed when on the details page. `undefined` if on another
     * page.
     */
    readonly currentAsset$ = new BehaviorSubject<Asset | undefined>(undefined);

    /** Tracks from the current asset. */
    readonly currentAssetTrack$ = new BehaviorSubject<AssetTrack | undefined>(undefined);

    /** Current details player time */
    readonly playerUpdate$ = new ReplaySubject<PlayerUpdate>(1);

    /** Current details player time */
    readonly currentPlayerTime$ = new ReplaySubject<number | undefined>(1);

    /** Current clip mark in / mark out selection  */
    readonly clipMarking$ = new BehaviorSubject<ClipMarking | undefined>(undefined);

    /** Emits when a play-feed is selected */
    readonly playFeedClickTime$ = new Subject<number>();

    /** Current opened tab on the persistent panel. */
    readonly currentPersistentTab$ = new BehaviorSubject<PersistentTab>('clipbins');

    /**
     * Clip bin currently selected from the persistent panel. This may be
     * different from the clipbin of `currentAsset` displayed in the Details view
     * and breadcrumb.
     */
    readonly persistentBinName$ = new BehaviorSubject<string | undefined>(undefined);

    /**
     * Clips currently displayed in the persistent panel. Also used for
     * navigation in the details view when the current clip belongs to the same
     * clipbin.
     */
    readonly currentPersistentClips$ = new ReplaySubject<Clip[]>(1);

    /**
     * Current asset for Insights tab, used to only make keyword searches while
     * that tab is opened.
     */
    readonly currentInsightAsset$ = new BehaviorSubject<Asset | undefined>(undefined);

    /**
     * Current keywords searched in the Insights panel.
     */
    readonly currentKeywords$ = new BehaviorSubject<string[]>([]);

    /**
     * Current array of search segment results from the insights panel. Also used
     * in the timeline.
     */
    readonly currentKeywordResults$ = new BehaviorSubject<KeywordResult[]>([]);

    /**
     * Current selected chip in the Insights Panel. Also used in the timeline to
     * highlight relevant segments above the timeline.
     */
    readonly currentSelectedKeyword$ = new BehaviorSubject<string | undefined>(undefined);

    /** Whether to show all clipbins or only those of the current user. */
    readonly clipbinsOwner$ = new BehaviorSubject(ClipbinsOwner.USER);

    /**
     * Controls persistent panel expanded/collapsed state. Use `true` to expand
     * and `false` to collapse.
     */
    readonly togglePersistentPanel$ = new Subject<boolean>();

    /**
     * Latest shortcut event emitted.
     */
    readonly shortcutEvent$ = new BehaviorSubject<ShortcutEvent | null>({} as ShortcutEvent);

    readonly scrollHappened$ = new BehaviorSubject<Event | null>(null);
}

/** Stub of StateService for unit tests. */
@Injectable()
export class StateServiceStub extends StateService {
  override currentAsset$ =
      new BehaviorSubject<Asset|undefined>(makeFakeOriginal({
        title: 'StateService Default Asset',
        name: 'state_service_default_asset',
      }));
}
