/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import * as CryptoJS from 'crypto-js';

import { environmentDetails } from './environment_cred';
import { Environment, environmentDefaults } from './environment_types';

const decrypted = CryptoJS.AES.decrypt(environmentDetails.encrypt, environmentDetails.enryptID);
const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
const cred = JSON.parse(decrypt);

/** Makes real API calls */
export const environment: Environment = {
  ...environmentDefaults,
  tag: 'dev',
  projectId: cred.projectId,
  clientId:
    cred.dev_client,
  mamApi: {
    baseUrl:
      'https://staging-intelligentassetservice-googleapis.sandbox.google.com',
    parent:
      'projects/234973717435/locations/global',
    bucketRootUri:
      'gs://foxsports-ias-sandbox-archive01/'
  },
  resourcesApi: `https://nestbff-dev-dot-foxsports-iasui-sandbox.uc.r.appspot.com/api/v1`,
  pubsubApi: {
    baseUrl: 'https://pubsub.googleapis.com',
    topic: 'projects/foxsports-mediaarchive-sandbox/topics/ias_metadata_topic',
  },
  stackDriverErrorReportingApiKey: cred.dev_stack,
  firebaseConfig: {
    apiKey: cred.dev_fireapi,
    appId: '1:369705616743:web:ae51865649cbfcfcd21bc2',
    projectId: cred.projectId
  },
  maintenance: false,

  hlsPlayback: {
    signatureTtl: { minutes: 5 },
    urlMapping: {
      from: 'https://storage.googleapis.com/foxsports-ias-sandbox-archive01/',
      to: 'https://playback.dev-app.ias.fox/'
    }
  },
  defaultSuggestions: cred.defaultSuggestions,
};
