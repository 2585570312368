import {Pipe, PipeTransform} from '@angular/core';


import {Asset} from '../services/asset_service';
import {RenditionVersion, RenditionVersionEnum} from '../services/ias_types';
import { RenditionService } from '../services/rendition/rendition.service';

/**
 * Get a preview URL with a framerate that is optimized for the duration that
 * will be rendered
 */
@Pipe({name: 'bestSeekUrl'})
export class BestSeekUrlPipe implements PipeTransform {
  constructor(private readonly renditionService: RenditionService){}

  async transform(asset: Asset, seekDuration = asset.duration): Promise<string | undefined> {
    const originalDuration = asset.original?.duration || asset.duration;
    let version;

    if (seekDuration / originalDuration > this.RATIO_FOR_DYNAMIC) {
      version = RenditionVersionEnum.SMALL_DYNAMIC_FPS;
    }
    else if (seekDuration <= 100) {
      version = RenditionVersionEnum.SMALL_HIGH_FPS;
    }
    else if (seekDuration <= 500) {
      version = RenditionVersionEnum.SMALL_MID_FPS;
    }
    else {
      version = RenditionVersionEnum.SMALL_LOW_FPS;
    }

    return this.getRendition(asset, version);
  }

  /**
   * From which seek duration over full durartion ratio should we use a dynamic
   * FPS rendition (optimized for long durations) instead of a fixed-FPS one.
   */
  private readonly RATIO_FOR_DYNAMIC = 0.75;

  private async getRendition(asset: Asset, version: RenditionVersion) {
    return this.renditionService.getRenditionUrl(asset, version, true);
  }
}
