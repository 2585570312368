import { Injectable, NgZone } from '@angular/core';
import { DocumentSnapshot, orderBy, QueryConstraint, startAfter, where } from '@firebase/firestore';
import { BehaviorSubject, combineLatest, from, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { AuthService } from 'auth/auth_service';
import { BinSectionContentType } from 'services/bin.service';

import { FirebaseResolver } from './firebase_resolver';


export interface FolderItemRef {
  id: string; // folder id
  queryableName: string; //property for search
  displayName: string; //folder name or clip bin displayName
  refType: 'folder' | 'clipbin'; // type of reference
  binRefName?: string // id (name) of the bin
  contentType?: BinSectionContentType;
  createdBy?: string; // user who created
  createdByName?: string; // name of the user who created
  createdAt?: Date; //date of creation
  updatedBy?: string; // for name changes or undeletes in the future
  updatedAt?: Date; // date of chage
  parent?: string | null; //id of parent folder
  items?: number; // amount of folders, bins or clips contained in the ref
  active?: boolean; // variable for soft deleting
  name?: string; // name of the
  level?: number;
  directChildrenCount?: number;
  breadcrumbPath?: string;
}


@Injectable({
    providedIn: 'root'
})
export class ClipBinsFoldersService {
    lastQueryTotalCount$ = new BehaviorSubject<number>(0);

    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly authService: AuthService,
        private readonly ngZone: NgZone
    ) {}

    queryFolders(constraints: QueryConstraint[], limitSize: number) {
        return this.queryDocument(constraints, limitSize).pipe(
        map(documents =>
          documents.map(document => {
                    return {
                        ...document.data(),
                        id: document.id
                    } as FolderItemRef;
                })
            ),
        switchMap(folderItems =>
                this.countDocuments(constraints).pipe(
            tap(count => this.lastQueryTotalCount$.next(count)),
                    map(() => folderItems) // Pass the folderItems along the chain
                )
            )
        );
    }

    private queryDocument(constraints: QueryConstraint[], limitSize: number) {
        return this.firebaseResolver.queryCollection('ias-clipbin-folders', constraints, limitSize);
    }

    private countDocuments(constraints: QueryConstraint[]) {
        const cleanConstraints = constraints.filter((constraint) => constraint.type !== `startAfter`);
        return this.firebaseResolver.countQuery('ias-clipbin-folders', cleanConstraints);
    }

    getAllFolders(limitSize = 6) {
        return this.queryFolders([where('refType', `==`, `folder`), where('active', '==', true)], limitSize);
    }

    getAllFoldersPaginated(limitSize = 6, lastDoc?: FolderItemRef) {
        const constraints = [
            where('active', '==', true),
            where('refType', '==', 'folder'),
            orderBy('createdAt', `asc`),
      orderBy('queryableName'),
        ];
        return lastDoc
            ? this.firebaseResolver.getDocument('ias-clipbin-folders', lastDoc.id).pipe(
                  take(1),
                  switchMap((document: DocumentSnapshot) =>
                      this.queryFolders(
                          [
                              ...constraints,
                              startAfter(document.data()?.['createdAt'], document.data()?.['queryableName'])
        ], limitSize))
      ) : this.queryFolders(constraints, limitSize);
    }

    getFolderRefByBinName(name: string) {
        return this.queryFolders([where('binRefName', '==', name), where('active', '==', true)], 1);
    }

    deleteBinInFolders(binName: string) {
        return this.getFolderRefByBinName(binName).pipe(
            take(1),
            map((folder) => {
                if (folder.length) {
                    return combineLatest([
                        this.updateFolder({ ...folder[0], active: false } as FolderItemRef),
              folder[0].parent ? this.getFolder(folder[0].parent) : of(null),
                    ])
                        .pipe(
                            take(1),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            map(([_, parentFolder]) => {
                                if (parentFolder) {
                                    parentFolder.items = parentFolder.items ? parentFolder.items - 1 : 0;
                                    parentFolder.contentType = parentFolder.items
                                        ? parentFolder.contentType
                                        : `folders`;
                                    return this.updateFolder(parentFolder);
                                }
                                return of(null);
                            })
                        )
                        .subscribe();
                }
                return of(null);
            })
        );
    }

    createFolder(data: FolderItemRef) {
        data.createdBy = this.authService.getUserEmail();
        data.createdByName = this.authService.getUserName();
        data.createdAt = new Date();
        data.queryableName = data.displayName.toLocaleLowerCase().replace(/\s/g, '');
        data.active = true;
        data.contentType = `folders`;
        return of(
            this.ngZone.runOutsideAngular(() => {
                return this.firebaseResolver.createFirestoreDoc('ias-clipbin-folders', data);
            })
        );
    }

    updateFolder(data: FolderItemRef, oldParent?: FolderItemRef): Observable<unknown> {
        data.updatedBy = this.authService.getUserEmail();
        data.updatedAt = new Date();
        if (data.active === false) {
            this.getFolderContent(data.id)
                .pipe(take(1))
                .subscribe((content) => {
                    content.forEach((item) => {
                        item.updatedAt = new Date();
                        item.updatedBy = this.authService.getUserEmail();
                        this.updateFolder({ ...item, parent: null } as FolderItemRef, data);
                    });
                });
        }
        if (oldParent) {
            if (oldParent.items) {
                oldParent.items--;
            }
            oldParent.contentType = oldParent.items ? oldParent.contentType : `folders`;
            this.updateFolder(oldParent).pipe(take(1)).subscribe(); //TODO: double check behaviors
        }
        if (data.parent) {
            return this.getFolder(data.parent).pipe(
                map((parentFolder: FolderItemRef) => {
                    if (parentFolder.items) {
                        parentFolder.items++;
                    } else {
                        parentFolder.items = 1;
                    }
                    parentFolder.updatedBy = this.authService.getUserEmail(); // TODO: Double check
                    parentFolder.updatedAt = new Date(); // TODO: Double check
                    parentFolder.contentType = data.binRefName ? `bins` : `folders`;
                    return this.updateFolder(parentFolder);
                }),
                tap(() => {
                    return from(
                        this.ngZone.runOutsideAngular(() => {
                            return this.firebaseResolver.updateFirestoreDoc('ias-clipbin-folders', data.id, data);
                        })
                    );
                })
            );
        }
        return from(
            this.ngZone.runOutsideAngular(() => {
                return this.firebaseResolver.updateFirestoreDoc('ias-clipbin-folders', data.id, data);
            })
        );
    }

    searchFolders(searchTerm: string, limitSize = 6, lastDoc?: FolderItemRef) {
        const comparisonTerm = searchTerm?.toLocaleLowerCase().replace(/\s/g, '');
        const constraints = [
            where('active', '==', true),
            where('queryableName', '>=', comparisonTerm),
            where('queryableName', '<=', comparisonTerm + '\uF8FF'),
            where('refType', '==', 'folder'),
      orderBy('createdAt', `asc`),
        ];
        return lastDoc
            ? this.firebaseResolver.getDocument('ias-clipbin-folders', lastDoc.id).pipe(
                  take(1),
        switchMap((document: DocumentSnapshot) => this.queryFolders([
          ...constraints,
          startAfter(document.data()?.['createdAt'])
        ], limitSize))
      ) : this.queryFolders(constraints, limitSize);
    }

    getFolderContent(id: string) {
    return this.queryFolders([
      where('parent', '==', id),
    ], 9000); //TODO: double check behaviors
    }

    getMyFolders(limitSize = 6, lastDoc?: FolderItemRef) {
        const currentUser = this.authService.getUserEmail();
        const constraints = [
            where('createdBy', '==', currentUser),
            where('active', '==', true),
            where('refType', '==', 'folder'),
      orderBy('createdAt', `asc`),
        ];
        return lastDoc
            ? this.firebaseResolver.getDocument('ias-clipbin-folders', lastDoc.id).pipe(
                  take(1),
        switchMap((document: DocumentSnapshot) => this.queryFolders([
          ...constraints,
          startAfter(document.data()?.['createdAt'])
        ], limitSize))
      ) : this.queryFolders(constraints, limitSize);
    }

    searchMyFolders(searchTerm: string, limitSize = 6, lastDoc?: FolderItemRef) {
        const comparisonTerm = searchTerm?.toLocaleLowerCase().replace(/\s/g, '');
        const currentUser = this.authService.getUserEmail();
        const constraints = [
            where('createdBy', '==', currentUser),
            where('active', '==', true),
            where('queryableName', '>=', comparisonTerm),
            where('queryableName', '<=', comparisonTerm + '\uF8FF'),
            orderBy('queryableName'),
            where('refType', '==', 'folder'),
      orderBy('createdAt', `asc`),
        ];
        return lastDoc
            ? this.firebaseResolver.getDocument('ias-clipbin-folders', lastDoc.id).pipe(
                  take(1),
        switchMap((document: DocumentSnapshot) => this.queryFolders([
          ...constraints,
          startAfter(document)
        ], limitSize))
      ) : this.queryFolders(constraints, limitSize);
    }

    getFolder(id: string) {
        return this.firebaseResolver.getDocument('ias-clipbin-folders', id).pipe(
            map((document: DocumentSnapshot) => {
                return {
                    ...document?.data(),
                    id: document?.id
                } as FolderItemRef;
            })
        );
    }
}
