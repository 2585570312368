import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';


import { FolderItemRef } from '../../firebase/clip_bins_folders.service';
import { ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { SnackBarService } from '../../services/snackbar_service';

/** Clipbin deletion dialog */
@Component({
  selector: 'mam-delete-bin-dialog',
  templateUrl: './clipbin_folder_delete_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipBinFolderDeleteDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  contextId = window.location.pathname.split('/').pop() || '';

  constructor(
    readonly dialogRef: MatDialogRef<ClipBinFolderDeleteDialog>,
    private readonly snackBar: SnackBarService,
    private readonly resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) public data: FolderItemRef) { }

  ngOnInit() {

    this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
      if (!deletionConfirmed) return;
      this.resourceService.deleteResource('folder', this.data?.id, this.contextId)
        .pipe(
          take(1)
        )
        .subscribe({
          next: () => {
            //TODO: remove references in folder
            this.snackBar.message('Folder has been deleted successfully.');
          },
          error: (error) => {
            this.snackBar.error(
              'Folder could not be deleted.', undefined, error);
          }
        });
    });
  }
}
