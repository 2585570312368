import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { PluginService } from '../plugin/plugin_service';
import { MediaCacheService } from '../services/media_cache_service';
import { HomeView, StateService } from '../services/state_service';
import { ChangeRegionComponent } from '../shared/change_region';

import { NavigateToClipBinDialog } from './navigate_to_clip_bin_dialog';
import { SharedLinksManagerDialog } from './shared_links_manager_dialog';

/**
 * Image used when no user icon is available, for instance during local
 * development when OAuth is not enabled.
 */
export const USER_ICON_PLACEHOLDER = 'images/user_icon_placeholder.png';

/**
 * A menu group containing the company logo, user icon, and options
 */
@Component({
  selector: 'mam-toolbar-menu',
  templateUrl: './toolbar_menu.ng.html',
  styleUrls: ['./toolbar_menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarMenu implements OnDestroy {
  constructor(
      private readonly authService: AuthService,
      private readonly dialog: MatDialog,
      private readonly analyticsService: FirebaseAnalyticsService,
      readonly pluginService: PluginService,
      readonly stateService: StateService,
      readonly mediaCache: MediaCacheService
  ) {}

  /**
   * Gets user icon from OAuth response. Defaults to a placeholder if not
   * available, for instance when the environment's `useMockService` setting is
   * set to `false`.
   */
  userIconUrl = this.authService.getUserIcon() || USER_ICON_PLACEHOLDER;

  userIsInternal = !!this.authService.isInternalUser();
  readonly HomeView = HomeView;

  // Used to unsubscribe from layout changes.
  private readonly destroyed$ = new ReplaySubject<void>(1);

  readonly selectableSites$ = this.mediaCache.state.selectableSites$;

  async logout() {
    await this.authService.logout();
    this.analyticsService.logEvent('logout', {
      eventType: AnalyticsEventType.NAVIGATION,
    });
  }

  openSharedLinksManagerDialog() {
    this.dialog.open(
        SharedLinksManagerDialog, SharedLinksManagerDialog.dialogOptions);
  }

  openNavigateToClipBin() {
    // The plugin does not have a browser URL bar, so when we are in the plugin
    // this will allow a user to paste a URL to a clip bin and navigate.
    this.dialog.open(
        NavigateToClipBinDialog, NavigateToClipBinDialog.dialogOptions);
  }

  loadUserIconPlaceholder() {
    this.userIconUrl = USER_ICON_PLACEHOLDER;
  }

  /** Executes to update the rgion in mobiles devices */
  openChangeRegion() {
    this.dialog.open(ChangeRegionComponent, {
      ...ChangeRegionComponent.dialogOptions,
      width: '250px',
      panelClass: 'change-region-dialog'
    });

  }


  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
