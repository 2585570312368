import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ScrubbingService } from 'shared/scrubbing_service';

import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { Asset, AssetService, Original } from '../services/asset_service';
import { Bin, BinWithClips } from '../services/bin.service';
import { MediaCacheService } from '../services/media_cache_service';
import { SearchInputService } from '../services/search_input_service';
import { HomeView, StateService } from '../services/state_service';

/**
 * Default view of the home page
 */
@Component({
	selector: 'mam-landing',
	templateUrl: './landing.ng.html',
	styleUrls: ['./landing.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Landing implements OnInit, OnDestroy {
	@ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;
	readonly HomeView = HomeView;

	/** Assets, the 3 most recently accessed clips */
	recentAssets: Original[] = [];
	cachedBins: Bin[] = [];

	/* Loading */
	isAssetsLoading = true;
	assetsLoading = Array.from({ length: 3 });

	private readonly destroyed$ = new ReplaySubject<void>(1);

	constructor(
		private readonly assetService: AssetService,
		private readonly cdr: ChangeDetectorRef,
		private readonly ngZone: NgZone,
		readonly searchInputService: SearchInputService,
		private analyticsService: FirebaseAnalyticsService,
		readonly stateService: StateService,
		readonly mediaCache: MediaCacheService,
		readonly scrubbingService: ScrubbingService,
    readonly route: ActivatedRoute,
	) {
		this.analyticsService.logEvent('Visited landing', {
			eventType: AnalyticsEventType.NAVIGATION,
			path: '/',
			string2: '/',
		});
	}

	ngOnInit() {
		// Observe periodic updates outside of NgZone so that e2e tests are not stuck waiting for this observable to complete.
		this.ngZone.runOutsideAngular(() => {
			this.assetService
				.watchRecents()
				.pipe(takeUntil(this.destroyed$))
				.subscribe((assets) => {
					this.ngZone.run(() => {
						this.isAssetsLoading = false;
						if (!assets) return;
						this.recentAssets = assets;
						// Save recent assets for navigating between them when we open the details page.
						this.assetService.cachedRecents = assets;
						this.cdr.detectChanges();
					});
				});
		});
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((url) => {
      if (url[0]?.path === 'folders') {
        setTimeout(() => {
          this.scrollableView.nativeElement.scrollTop = window.innerHeight;
        }, 300);
      }
    });
	}

  onScroll(evt: Event){
    this.stateService.scrollHappened$.next(evt);
  }

	trackName(index: number, value: BinWithClips | Asset) {
		return value.name;
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
