import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  map,
  merge,
  Observable,
  ReplaySubject,
  startWith,
  switchMap,
  take,
  takeUntil
} from 'rxjs';



import { AuthService } from '../../auth/auth_service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { ClipBinsFoldersService } from '../../firebase/clip_bins_folders.service';
import { FirebaseAnalyticsService } from '../../firebase/firebase_analytics_service';
import { Asset, Clip } from '../../services/asset_service';
import { Bin, BinSectionContent, BinSectionContentType, BinService, BinWithClips } from '../../services/bin.service';
import { ClipbinsOwner } from '../../services/bin_api.service';
import { ClipApiService } from '../../services/clip_api_service';
import { MediaCacheService } from '../../services/media_cache_service';
import { Pagination, PaginationService } from '../../services/pagination_service';
import { PaginatorIntl } from '../../services/paginator-intl';
import { SearchType } from '../../services/search_api.service';
import { SearchInputService, Suggestion } from '../../services/search_input_service';
import { SearchSuggestionsHistory, SuggestionStorageType } from '../../services/search_suggestions_history';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { DeleteMultipleBinDialog } from '../../shared/delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import { ScrubbingService } from '../../shared/scrubbing_service';
import { BINS_PAGINATION_BREAKPOINTS, Breakpoint, DisplayModePagination } from '../landing-helpers.utils';

import { PaginationInfo, Resource, ResourceService, ResourceType, SearchOptions } from './service/resource.service';


const DEFAULT_BINS_PAGE_SIZE = 12;
const SEARCH_DEBOUNCE = 500;

export interface FoldersApiResult {
  folders: Resource[];
  paginationData: PaginationApiResult;
}

export interface PaginationApiResult {
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

type pageResult = {
  assets?: Asset[],
  bins?: Bin[],
  nextPageToken?: string;
};
@Component({
  selector: 'mam-clip-bin-section',
  templateUrl: './clip-bin-section.component.html',
  styleUrl: './clip-bin-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
})
export class ClipBinSection implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('searchField') searchField?: MatFormField;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger?: MatAutocompleteTrigger;
  @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;

  /* Pagination */
  binsPagination: Pagination<pageResult>;
  clipsPagination: Pagination<pageResult>;
  foldersPagination = {
    pageLimit: 6,
    nextCursor: null as Resource | null,
    lastCursor: null as Resource | null,
    pageIndex: 0,
    totalCount: 0,
  };

  pageIndex$ = new BehaviorSubject(0);

  /* Loading */
  resultsLoading = true;
  resultsLoading$: Observable<number[]> = this.breakpointObserver.observe(['(max-width: 1300px)']).pipe(
    take(1),
    map((result) =>
      result.matches ? Array.from({ length: this.retrievePaginationLimit() }) : Array.from({ length: 5 }),
    ),
  );

  /* Controls lock */
  controlsLocked$ = new BehaviorSubject<boolean>(false);

  /* SearchResults */
  private searchChanged$: Observable<string | null>;
  searchControl = new FormControl<string | null>('');
  assetOwner$ = new ReplaySubject<ClipbinsOwner>(1);
  resources$ = new BehaviorSubject<Bin[] | Clip[] | Resource[]>([]);

  get binResults() {
    return this.resources$.value as Bin[];
  }

  get clipResults() {
    return this.resources$.value as Clip[];
  }

  get folderResults() {
    return this.resources$.value as Resource[];
  }

  /** searchMode array of available choices. */
  // searchModeOptions =  this.featureService.featureOn('enable-clip-search') ? [BinSectionContent.FOLDER, BinSectionContent.BIN, BinSectionContent.CLIP] : [BinSectionContent.FOLDER, BinSectionContent.BIN];
  searchModeOptions = this.featureService.featureOn('enable-clip-search') ? [BinSectionContent.FOLDER, BinSectionContent.CLIP] : [BinSectionContent.FOLDER];
  searchModeSelected: BinSectionContentType = BinSectionContent.FOLDER;
  searchModeSelected$: BehaviorSubject<BinSectionContentType> = new BehaviorSubject<BinSectionContentType>(BinSectionContent.FOLDER);
  searchModeVisible: BinSectionContentType = BinSectionContent.FOLDER;
  searchText: string | null = null;

  displayMode = DisplayMode.GRID;
  SEARCH_MODE = BinSectionContent;

  searchModeDisabled = false;

  // Display booleans
  showAllAssets: boolean = false;
  // make the toggle disabled when the search mode is clips
  isShowAllAssetsDisabled: boolean = false;
  isPaginatorDisabled: boolean = false;

  // pagination
  currentPagination$: BehaviorSubject<DisplayModePagination[]> = new BehaviorSubject<DisplayModePagination[]>(
    BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || [],
  );

  folderUrlId: string | null = null;

  protected activeSuggestions$: BehaviorSubject<Suggestion[]> = new BehaviorSubject<Suggestion[]>([]);
  protected _suggestions: Suggestion[] = [];
  protected _suggestionHistory: Suggestion[] = [];

  /** Account data */
  username: string = '';
  protected isAdmin: boolean = false;
  protected userEmail: string = '';

  // persist clipbin multiselection
  itemMultiSelection = new Set<string>();
  lastSelectedIndex: number = 0;

  get allChecked() {
    return (
      this.resources$.value.length > 0 &&
      this.itemMultiSelection.size === this.resources$.value.length
    );
  }

  get someChecked() {
    return (
      this.resources$.value.length > 0 &&
      this.itemMultiSelection.size > 0 &&
      this.itemMultiSelection.size < this.resources$.value.length
    );
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly binService: BinService,
    private readonly clipService: ClipApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: SnackBarService,
    readonly searchInputService: SearchInputService,
    private analyticsService: FirebaseAnalyticsService,
    private authService: AuthService,
    readonly stateService: StateService,
    readonly mediaCache: MediaCacheService,
    readonly scrubbingService: ScrubbingService,
    private readonly paginationService: PaginationService,
    private readonly breakpointObserver: BreakpointObserver,
    protected readonly suggestionHistory: SearchSuggestionsHistory,
    private readonly clipBinFolderService: ClipBinsFoldersService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly featureService: FeatureFlagService,
    private readonly resourceService: ResourceService,
    private readonly router: Router
  ) {
    // Init variables
    this.searchInputService.searchType$.next(SearchType.VOD);
    this.searchChanged$ = this.getSearchChanged();
    this.assetOwner$.next(stateService.clipbinsOwner$.value);
    this.binsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
    this.clipsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
    // this.foldersPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
    this.binService.displayMode$.next(DisplayMode.GRID);

    this.currentPagination$.next(BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []);

    this.binService.displayMode$.subscribe((mode) => {
      this.displayMode = mode ?? DisplayMode.GRID;
    });

    // Define and listen for resize to the screen breakpoint
    // this.listenForScreenBreakpoint();

    // Resets pagination and scrolls top when search value changes or clip bins get updated
    this.listenForSearchAndPageChanges();
    // Get user's name
    const accountUserName = this.authService.getUserName();
    this.username = accountUserName.split(' ')[0] || 'User';

    this.listenToSuggestionsChanges();

    this.isAdmin = this.authService.isAdmin;
    this.userEmail = this.authService.getUserEmail();
  }

  ngOnInit() {
    this.updateResults();
    this.listenForNavigationChanges();
    this.resourceService.currentResources$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((resources) => {
      this.resources$.next(resources as unknown as Resource[]);
    });
    this.resourceService.paginationInfo$.pipe(takeUntil(this.destroyed$)).subscribe(pagination => {
      if (pagination.paginationResult) {
        this.foldersPagination.pageLimit = pagination.paginationResult.pageSize;
        this.foldersPagination.totalCount = pagination.paginationResult.totalItems;
        this.foldersPagination.pageIndex = pagination.offset;
      }
    });
    this.stateService.scrollHappened$
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe({
      next: () => {
        this.matAutocompleteTrigger?.closePanel();
      }
    });
    this.controlsLocked$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.isShowAllAssetsDisabled = value;
      this.isPaginatorDisabled = value;
    });
  }

  getPaginatedResources(resourceType: ResourceType, owner: string, searchTerm: string, pageIndex?: number, pageSize?: number, startAfter?: string) {
    this.controlsLocked$.next(true);
    this.resultsLoading = true;
    const pSize = pageSize || DEFAULT_BINS_PAGE_SIZE;
    const pIndex = pageIndex || 0;
    const paginatedValue: PaginationInfo = {
      limit: pSize,
      offset: pIndex
    };

    if (startAfter) paginatedValue.startAfter = startAfter;

    const searchOptions: SearchOptions = {
      searchTerm: searchTerm,
      owner: owner === 'current_user' ? this.authService.getUserEmail() : ''
    };

    this.stateService.clipbinsOwner$.next(owner === 'current_user' ? ClipbinsOwner.USER : ClipbinsOwner.ALL);

    const urlTree = this.router.parseUrl(this.router.url);
    const isNestedFolder = urlTree.root.children['primary']?.segments[0]?.path === 'folders';
    const parentId = urlTree.root.children['primary']?.segments[1]?.path;

    if(isNestedFolder && parentId){
      this.resourceService.getResourceChildren('folder', parentId, paginatedValue)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          complete: () => {
            this.resultsLoading = false;
            this.controlsLocked$.next(false);
          }
        });
    } else {
      this.resourceService
        .getResource(resourceType, paginatedValue, searchOptions)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          complete: () => {
            this.resultsLoading = false;
            this.controlsLocked$.next(false);
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.registerKeydownListeners();
  }

  updateSearchSuggestions() {
    if (this.searchControl.value) {
      this.suggestionHistory.add(this.searchControl.value, SuggestionStorageType.VOD_SEGMENT_SEARCH);
      this.analyticsService.logSearchEvent('Add search term', { term: this.searchControl.value });
      return;
    }
  }

  listenForNavigationChanges() {
    combineLatest([this.route.url, this.route.queryParams])
      .pipe(takeUntil(this.destroyed$)).subscribe(([url]) => {
        this.resultsLoading = true;
        if (url[0]?.path === 'folders') {
          this.folderUrlId = url[1]?.path;
          this.searchModeSelected = BinSectionContent.FOLDER;
          this.resultsLoading = false;
        }
        this.setIsShowAllDisabled();


        // if (params?.['mySearchMode']) {
        //   this.searchModeSelected$.next(params['mySearchMode'] as BinSectionContentType);
        //   this.searchModeSelected = params['mySearchMode'] as BinSectionContentType;
        //   this.cdr.detectChanges();
        // }
      });

  }

  listenForSearchAndPageChanges() {
    this.resources$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.resultsLoading = false;
    });
    merge(this.searchChanged$, this.assetOwner$, this.binService.binsUpdated$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetPagination();
        this.updateSearchSuggestions();
        this.activeSuggestions$.next(this.searchText ? this._suggestions : this._suggestionHistory);
        this.cdr.markForCheck();
      });

    // Update current page index onPageChange events
    this.pageIndex$.pipe(takeUntil(this.destroyed$)).subscribe((pageIndex) => {
      switch (this.searchModeVisible) {
        case BinSectionContent.CLIP:
          this.clipsPagination.pageIndex = pageIndex;
          this.binsPagination.pageIndex = 0;
          this.foldersPagination.pageIndex = 0;
          break;
        case BinSectionContent.BIN:
          this.binsPagination.pageIndex = pageIndex;
          this.clipsPagination.pageIndex = 0;
          this.foldersPagination.pageIndex = 0;
          break;
        case BinSectionContent.FOLDER:
          this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
          this.foldersPagination.nextCursor = this.folderResults[this.folderResults.length - 1];
          this.foldersPagination.pageIndex = pageIndex;
          this.clipsPagination.pageIndex = 0;
          this.binsPagination.pageIndex = 0;
          break;
      }
    });
  }

  listenToSuggestionsChanges(): void {
    //Updates list of suggestions based on the current search input, if there is any
    this.searchInputService.currentSearchSuggestions$.pipe(takeUntil(this.destroyed$)).subscribe((suggestions) => {
      this._suggestions = suggestions;
      this.activeSuggestions$.next(this._suggestions);
      if (this._suggestionHistory.length < 3) {
        this._suggestionHistory = suggestions;
      }
    });
    //Initializes the suggestion history

    //Updates the list of suggestion based on search history
    this.searchInputService.suggestionHistory$.pipe(takeUntil(this.destroyed$)).subscribe((suggestions) => {
      this._suggestionHistory = suggestions;
    });
  }

  /**
   * Search bins
   */
  searchBins(searchTerm?: string, owner: ClipbinsOwner = ClipbinsOwner.USER) {
    const searchResult = this.binService
      .list({ title: searchTerm, owner }, this.binsPagination.nextPageToken, this.retrievePaginationLimit());
    return searchResult;
  }

  /**
   * Search clips
   */
  searchClips(searchTerm: string | null) {
    if (!searchTerm) return EMPTY;

    return this.clipService
      .searchClipsByTitle(
        searchTerm, /* query: string */
        this.retrievePaginationLimit(), /* pageSize:number*/
        this.clipsPagination.nextPageToken /* pageToken:string */
      );
  }


  /**
   * Updates status related to pagination calculation
   */
  private updateBinsResults(res: pageResult) {
    const pageLimit = this.retrievePaginationLimit();
    const { bins, nextPageToken } = res;
    this.binsPagination.nextPageToken = nextPageToken;
    this.binsPagination.pageSize = pageLimit;
    const index = this.binsPagination.pageIndex;

    // Save new page results in cache. Avoid duplicates
    this.binsPagination.pagesCache[index] = res;

    // Combine all cached results into one list of assets for details navigation.
    const cachedResults: Bin[] = this.binsPagination.pagesCache.reduce<Bin[]>((segments, result) => {
      segments.push(...(result.bins || []));
      return segments;
    }, []);
    const portionStart = index * pageLimit;
    const portionEnd = portionStart + pageLimit;
    const portion = cachedResults.slice(portionStart, portionEnd);
    this.resultsLoading = false;
    this.resources$.next(portion);
    // We reached the last page, calculate the total number of results
    if (!nextPageToken) {
      const pagesCachedCount = this.binsPagination.pagesCache.length;
      const countUntilNow = (pagesCachedCount - 1) * this.binsPagination.pageSize;
      this.binsPagination.totalCount = countUntilNow + (bins?.length || 0);
    }
  }

  private updateClipsResults(res: pageResult) {
    const pageLimit = this.retrievePaginationLimit();
    const clips = res.assets as Clip[];
    const nextPageToken = res.nextPageToken;
    this.clipsPagination.nextPageToken = nextPageToken;
    this.clipsPagination.pageSize = pageLimit;
    const index = this.clipsPagination.pageIndex;

    // Save new page results in cache. Avoid duplicates
    this.clipsPagination.pagesCache[index] = res;

    // Combine all cached results into one list of assets for details navigation.
    const cachedResults: Clip[] = this.clipsPagination.pagesCache.reduce<Clip[]>((segments, result) => {
      segments.push(...result.assets as Clip[] || []);
      return segments;
    }, []);
    const portionStart = index * pageLimit;
    const portionEnd = portionStart + pageLimit;
    const portion = cachedResults.slice(portionStart, portionEnd);
    this.resultsLoading = false;
    this.resources$.next(portion);

    // We reached the last page, calculate the total number of results
    if (!nextPageToken) {
      const pagesCachedCount = this.clipsPagination.pagesCache.length;
      const countUntilNow = (pagesCachedCount - 1) * this.clipsPagination.pageSize;
      this.clipsPagination.totalCount = countUntilNow + (clips?.length || 0);
    }
  }

  private updateFoldersResults(res: Resource[]) {
    this.resultsLoading = false;
    this.resources$.next(res);
    this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
    this.foldersPagination.nextCursor = res[res.length - 1];
  }

  private resetPagination() {
    if (this.searchModeSelected == this.searchModeVisible && this.searchText) {
      this.resources$.next([]);
      this.pageIndex$.next(0);
    }

    const pageLimit = this.retrievePaginationLimit();
    this.binsPagination = this.paginationService.getEmptyPagination(pageLimit);
    this.clipsPagination = this.paginationService.getEmptyPagination(pageLimit);
    this.foldersPagination = {
      pageLimit,
      nextCursor: null,
      lastCursor: null,
      pageIndex: 0,
      totalCount: 0,
    };
    this.clipBinFolderService.getMyFolders();
  }

  /**
   * Loads the initial bins list for the current owner and updates the results based on changes.
   */
  updateResults() {
    combineLatest([
      this.searchChanged$,
      this.assetOwner$,
      this.pageIndex$,
      this.searchModeSelected$,
      this.binService.binsUpdated$,
      this.currentPagination$,
      this.binService.displayMode$,

    ])
      .pipe(
        debounceTime(500),
        //TODO: fix this!
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(([title, owner, pageIndex, searchModeSelected, binsUpdated]) => {
          //Set search mode in URL
          // const searchParams = new URLSearchParams(window.location.search);
          // searchParams.set("mySearchMode", searchModeSelected);
          // const locationUpdate = window.location.pathname + '?' + searchParams.toString();
          // window.history.replaceState(null, window.document.title, locationUpdate);
          // if(!binsUpdated){
          //   this.resultsLoading = true;
          // }

          const result = new Observable<pageResult | Resource[] | null>;
          // if (searchModeSelected == BinSectionContent.CLIP && this.searchText?.length) {
          //   const currentPage = of(this.clipsPagination.pagesCache[pageIndex]);
          //   result = (pageIndex < this.clipsPagination.pagesCache.length && this.clipsPagination.pagesCache[0].assets?.length === this.clipsPagination.pageSize)? currentPage : this.searchClips(title ?? '');
          // } else if (searchModeSelected == BinSectionContent.BIN || (searchModeSelected == BinSectionContent.CLIP && !this.searchText?.length)) {
          //   const currentPage = of(this.binsPagination.pagesCache[pageIndex]);
          //   if(binsUpdated?.type && 'DELETE,CREATE'.includes(binsUpdated.type)){
          //     result = this.searchBins(title ?? '', owner);
          //   }else{
          //     const pageSize = this.binsPagination.pageSize = this.retrievePaginationLimit();

          //     // eslint-disable-next-line unicorn/prefer-ternary
          //     if((pageIndex + 1) <= this.binsPagination.pagesCache.length && this.binsPagination.pagesCache[0] && pageSize === this.binsPagination.pagesCache[0].bins?.length){
          //       result = currentPage;
          //     }else if(this.binsPagination.pagesCache[0] && pageSize !== this.binsPagination.pagesCache[0].bins?.length){
          //       this.binsPagination.nextPageToken = '';
          //       result = this.searchBins(title ?? '', owner);
          //     }else{
          //       result = this.searchBins(title ?? '', owner);
          //     }
          //   }
          // } else if (searchModeSelected == BinSectionContent.FOLDER) {
          //   let cursorToLoad;
          //   if (pageIndex > 0 && this.folderResults.length) {
          //       this.foldersPagination.lastCursor = {...this.foldersPagination.nextCursor} as Resource;
          //       this.foldersPagination.nextCursor = this.folderResults[this.folderResults.length - 1];
          //       this.foldersPagination.pageLimit = this.retrievePaginationLimit() || 6;
          //       cursorToLoad = pageIndex >  this.foldersPagination.pageIndex ?  this.foldersPagination.nextCursor : this.foldersPagination.lastCursor;
          //     } else {
          //       this.foldersPagination.pageIndex = 0;
          //       this.foldersPagination.nextCursor = null;
          //       this.foldersPagination.lastCursor = null;
          //       cursorToLoad = undefined;
          //     }

          //   if (owner != ClipbinsOwner.USER) {
          //     result = title ? this.clipBinFolderService.searchFolders(title, this.foldersPagination.pageLimit, cursorToLoad) : this.clipBinFolderService.getAllFolders( this.foldersPagination.pageLimit);
          //   } else
          //   result = title ? this.clipBinFolderService.searchMyFolders(title, this.foldersPagination.pageLimit, cursorToLoad) : this.clipBinFolderService.getMyFolders( this.foldersPagination.pageLimit, cursorToLoad);
          // }
          const searchTerm = this.searchText as string;
          const searchOptions = this.resourceService.searchOptions$.value;
          this.showAllAssets = owner !== 'current_user';

          if (pageIndex == 0) {
            this.getPaginatedResources('folder', owner, searchTerm);
          } else {
            const comparableOwner = owner === ClipbinsOwner.USER ? this.userEmail : "";
            if (comparableOwner != searchOptions.owner) {
              this.getPaginatedResources('folder', owner, searchTerm);
            }
          }

          this.cdr.markForCheck();

          return result;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        if (!res) {
          this.snackBar.error(`${this.formatBinSectionContent(this.searchModeSelected)} could not be loaded`);
          this.resources$.next([]);
        } else {
          this.processSearchResults(res);
        }
        this.cdr.detectChanges();
      });
  }
  private processSearchResults(res: pageResult | Resource[] | null) {
    let result;
    switch (this.searchModeSelected) {
      case BinSectionContent.CLIP:
        result = res as pageResult;
        if (result.assets) {
          this.searchModeVisible = BinSectionContent.CLIP;
          this.updateClipsResults(result);
        } else {
          this.searchModeVisible = BinSectionContent.BIN;
          this.updateBinsResults(result);
        }
        break;
      case BinSectionContent.FOLDER:
        result = res as Resource[];
        this.searchModeVisible = BinSectionContent.FOLDER;
        this.updateFoldersResults(result);
        break;

      case BinSectionContent.BIN:
        this.searchModeVisible = BinSectionContent.BIN;
        result = res as pageResult;
        this.updateBinsResults(result);
        break;
    }
  }

  /**
   * Retrieves the pagination limit based on the breakpoint pagination options
   * and active display mode.
   */
  retrievePaginationLimit(): number {
    const currentBreakpoint = this.currentPagination$.getValue();
    const activeDisplayMode = currentBreakpoint.find((el) => el.displayMode === this.displayMode);
    if (!activeDisplayMode) return DEFAULT_BINS_PAGE_SIZE;
    return activeDisplayMode?.limit || 0;
  }

  getPlaceholderText() {
    if (!this.searchModeSelected) return '';
    switch (this.searchModeSelected) {
      case BinSectionContent.FOLDER:
        return 'Search folders';
      case BinSectionContent.BIN:
        return 'Search clip bins';
      case BinSectionContent.CLIP:
        return 'Search clips';
    }
  }

  formatBinSectionContent(mode: BinSectionContentType) {
    switch (mode) {
      case BinSectionContent.FOLDER:
        return 'Folders';
      case BinSectionContent.BIN:
        return 'Clip bins';
      case BinSectionContent.CLIP:
        return 'Clips';
    }
  }

  handleSearchModeChange(mode: BinSectionContentType) {
    if (this.searchModeSelected !== mode) {
      this.pageIndex$.next(0);
    }
    this.searchModeSelected = mode;
    this.searchModeSelected$.next(mode);
    if (mode !== BinSectionContent.BIN && this.showAllAssets) {
      this.toggleShowAllAssets(false);
    }

    this.setIsShowAllDisabled();
    this.itemMultiSelection.clear();
  }

  setIsShowAllDisabled() {
    this.isShowAllAssetsDisabled = !!this.folderUrlId || this.searchModeSelected === BinSectionContent.CLIP;
  }

  /** Toggle the visualization of the user's clip bins and all clip bins */
  onShowAllChange(event: MatSlideToggleChange) {
    this.toggleShowAllAssets(event.checked);
    this.itemMultiSelection.clear();
  }
  /* Handles the toggling of clip bins visualization with or without direct 'show all' event */
  toggleShowAllAssets(toggle: boolean | null = null): void {
    toggle == null ?
      (this.showAllAssets = !this.showAllAssets) :
      (this.showAllAssets = !!toggle);

    this.assetOwner$.next(this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER);
  }

  /** Toggles between grid and list view */
  toggleViewMode() {
    const nextMode = this.isGrid(this.displayMode) ? DisplayMode.LIST : DisplayMode.GRID;
    this.binService.displayMode$.next(nextMode);
  }

  isGrid(displayMode: DisplayMode) {
    return displayMode === DisplayMode.GRID;
  }

  /**
   * Listens for screen breakpoints and updates the pagination size accordingly.
   * Iterates through the breakpoints in order from largest to smallest.
   * Retrieves the pagination sizes defined for the active breakpoint
   */
  private listenForScreenBreakpoint() {
    this.breakpointObserver
      .observe(Array.from(BINS_PAGINATION_BREAKPOINTS.keys()))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ breakpoints }) => {
        const orderedBreakpoints = Object.keys(breakpoints) as Array<Breakpoint>;
        // go through each breakpoint in order from largest to smallest
        for (const currBreakpoint of orderedBreakpoints) {
          // if the breakpoint is active, then override the pagination sizes that are defined for it
          if (breakpoints[currBreakpoint]) {
            this.currentPagination$.next(BINS_PAGINATION_BREAKPOINTS.get(currBreakpoint) || []);
            this.binsPagination.pageSize = this.retrievePaginationLimit();
            this.clipsPagination.pageSize = this.retrievePaginationLimit();
            this.resetPagination();
          }
        }
        this.cdr.markForCheck();
      });
  }

  private getSearchChanged() {
    return this.searchControl.valueChanges.pipe(
      map((value) => {
        if (!value || !value.length) {
          this.searchText = null;
          return null;
        }
        this.searchText = value;
        this.searchInputService.partialQuery$.next(value);
        this.itemMultiSelection.clear();
        this.folderUrlId = null;
        return value;
      }),
      debounceTime(SEARCH_DEBOUNCE),
      startWith(''),
      distinctUntilChanged(),
    );
  }

  onSearchClear() {
    this.resultsLoading = true;
    this.searchControl.reset();
    this.searchText = null;
    this.matAutocompleteTrigger?.closePanel();
  }

  onPageChange(event: PageEvent) {
    this.resourceService.pageChanged$.next(null);
    this.resultsLoading = true;
    this.cdr.markForCheck();
    let startAfter: string = '';

    const urlTree = this.router.parseUrl(this.router.url);
    const isNestedFolder = urlTree.root.children['primary']?.segments[0]?.path === 'folders';

    const res = isNestedFolder ? this.resourceService.currentContext$.value.parent.children : this.resourceService.currentResources$.value;

    if (event.pageIndex !== 0) {
      if (event.previousPageIndex != null && event.pageIndex > event.previousPageIndex) {
        startAfter = res[res.length - 1].id as string;
      } else {
        const lastResource = this.resourceService.getLastResourceByPage(event.pageIndex - 1);
        if (lastResource)
          startAfter = lastResource.resourceId as string;
      }
      const owner = this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER;
      const searchTerm = this.searchText as string;
      this.getPaginatedResources('folder', owner, searchTerm, event.pageIndex, event.pageSize, startAfter);
    } else {
      this.pageIndex$.next(event.pageIndex);
    }

    this.itemMultiSelection.clear();
  }

  trackBy(index: number, value: Resource | BinWithClips | Asset | Clip) {
    return (value as BinWithClips | Asset | Clip)?.name ?? (value as Resource)?.id;
  }

  /** Removes all parentheses. */
  hideParens(suggestion = '') {
    return suggestion.replace(/[()]/g, '');
  }

  getSuggestionText(suggestion: Suggestion | null) {
    return suggestion?.text || '';
  }

  trackSuggestion(index: number, suggestion: Suggestion) {
    return suggestion?.text || '';
  }

  /** Tag words that match the prefix so they can be displayed bold. */
  getWordsStyles(text: string): Array<{ matches: boolean; value: string }> {
    const prefixes = this.searchControl.value?.trim()?.toLowerCase().split(' ') || [];
    return text?.split(' ').map((value) => {
      const matches = prefixes?.some((prefix: string) => prefix && value?.toLowerCase().startsWith(prefix));
      return { matches, value };
    });
  }

  removeFromHistory(suggestion: Suggestion, event?: MouseEvent) {
    event?.stopPropagation();
    this.searchInputService.removeFromHistory(suggestion);
  }

  onOptionSelected(suggestion: Suggestion) {
    if (!suggestion) return;
    this.onEnterPressed();
    this.cdr.detectChanges();
  }

  private registerKeydownListeners() {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        this.onEnterPressed();
      }
    };

    if (!this.searchInput) return;
    this.searchInput.nativeElement.addEventListener('keydown', onKeydown, { capture: true });
    this.destroyed$.subscribe(() => {
      document.removeEventListener('keydown', onKeydown);
    });
  }

  private onEnterPressed() {
    this.searchInput?.nativeElement.blur();
    this.matAutocompleteTrigger?.closePanel();
    this.searchField?._textField.nativeElement.blur();
  }

  /**
   * Toggles manual selection of a clip bin for bulk operation. If the "shift" key
   * is pressed, all clips between the last selected one and the current clicked
   * one will be toggled.
   */
  toggleSelection(event: MouseEvent, bin: Bin) {
    const currentIndex = (this.resources$.value as Bin[]).findIndex(b => b.name === bin.name);

    if (event.shiftKey && this.lastSelectedIndex !== null) {
      const start = Math.min(this.lastSelectedIndex, currentIndex);
      const end = Math.max(this.lastSelectedIndex, currentIndex);
      for (let i = start; i <= end; i++) {
        this.itemMultiSelection.add((this.resources$.value[i] as Bin).name);
      }
    } else {
      if (this.itemMultiSelection.has(bin.name)) {
        this.itemMultiSelection.delete(bin.name);
      } else {
        this.itemMultiSelection.add(bin.name);
      }
      this.lastSelectedIndex = currentIndex;
    }
    this.cdr.markForCheck(); // Trigger change detection
  }

  toggleSelectAll() {
    if (this.allChecked && this.itemMultiSelection.size > 0) {
      this.itemMultiSelection.clear();
    } else {
      if (this.searchModeVisible == BinSectionContent.BIN) {
        this.resources$.value.map((bin) => this.itemMultiSelection.add((bin as Bin).name));
      }
    }
    this.cdr.markForCheck();
  }

  deleteSelection() {
    this.dialog.open(DeleteMultipleBinDialog, {
      ...DeleteMultipleBinDialog.dialogOptions,
      data: {
        bins: this.itemMultiSelection,
        onConfirm: () => {
          this.pageIndex$.next(0);
        },
        onOpen: () => {
          this.cdr.detectChanges();
          this.itemMultiSelection = new Set();
        },
        onCancel: () => {
          this.cdr.detectChanges();
        }
      },
    });
  }

  ngOnDestroy() {
    this.pageIndex$.complete();
    this.assetOwner$.complete();
    this.resources$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
