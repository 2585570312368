<span class="folder-name">
  <img (click)="onFolderIconClick(); $event.preventDefault();" src="../../images/folder_icon_light.svg" alt="Folder icon">
  @if (resourceContent.parent) {
    <p>&emsp;>&emsp;</p>
    @if (resourceContent.parent.breadcrumb.length) {
      @for(crumb of resourceContent.parent.breadcrumb; track $index) {
      <span class="folder-crumb" (click)="onFolderCrumbClick(crumb.id!)">
        <span>{{crumb.name}}</span>
      </span>
      <p>&emsp;>&emsp;</p>
      }
    }
    <p>{{resourceContent.parent.name}}</p>
  }
</span>
<div class="content-wrapper">
  @if (!resultsLoading) {
  <div class="content-container" [class.folders_grid]="hasContent" [class.folders_grid--list]="displayMode === 'list'">
    @if (hasContent) {
      @for (folder of content; track trackBy) {
      <div class="folders_grid_card_container">
        <mam-clip-bin-folder-preview class="folders_grid__card" [isAdmin]="isAdmin" [userEmail]="userEmail"
          [showAllFolders]="showAllFolders" [folder]="folder" [viewMode]="displayMode">
        </mam-clip-bin-folder-preview>
      </div>

      }
    } @else {

    <mam-empty-assets-card [assetsType]="'content'" [hasSearchText]="false" [helperText]="true">
      <p>
        Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create bins and folders and start
        adding.
      </p>
    </mam-empty-assets-card>
    }
  </div>
  } @else {
  <!-- Loading -->
  <div class="skeletons">
    <mam-assets-skeleton class="mam-skeleton" *ngFor="let i of skeletonItems"
      [display]="displayMode"></mam-assets-skeleton>
  </div>
  }
</div>
